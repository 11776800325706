<template>
   <div id="saintOuen">
      <section id="saintOuen-1">
         <div>
            <VideoSaintOuen/>
            <div class="txt-hero"><h1 class="title-h1 txt-right" :class="{'anim-slide-right-blur':AnimtxtBlur}"><span>{{$t('titleSaintouen')['0']}}</span><span>{{$t('titleSaintouen')['1']}}</span><span>{{$t('titleSaintouen')['2']}}</span></h1></div>
            <div class="scroll-bottom">
               <a href="#" aria-label="scroll to next" class="btn btn-white btn-scroll" @click="scrollToNext">  
                  <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
               </a>
            </div>
         </div>
      </section>
                  
      <section id="saintOuen-2" class="saintOuen-2">
         <div class="wallpaper-zoom-inactive">
            <div class="loc-contain" :class="{'wallpaper-zoom': showZoom}">
               <figure class="desk-img"><img class="loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/desk-saintOuen-loc.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/desk-saintOuen-loc-retina.jpg 2x"' alt="bg-saintOuen-2"></figure>
               <figure class="mob-img"><img class="loadTemp_mobile" src="" :dtamob-src='this.$pathprod+"images/saint-ouen/mob-saintOuen-loc.png"' alt="bg-saintOuen-2"></figure>
               <div class="loc-bloc">
                  <div class="loc-bloc-contain">
                     <span class="loc-bloc-circle"></span> 
                     <span class="loc-bloc-txt" :class="{'anim-slide-right-blur':AnimLoc}">HARMONY</span>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <section id="suivre-ligne">
         <div class="map-ctn show_animBloc" v-view="fadeAnimBloc" :class="{'fade-up': show_animBloc}">
            <h2 class="title-h2">{{$t('titleSuivrelaligne')}}</h2>
            <figure>
               <img class="desk-img loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/desk-groupe-01.svg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/desk-groupe-01.svg"' alt="bg-saintOuen-3">
               <img class="mob-img loadTemp_mobile" src="" :dtamob-src='this.$pathprod+"images/saint-ouen/mob-groupe-01.svg"' alt="bg-saintOuen-3">
            </figure>
         </div>
      </section>

      <section id="saintOuen-4">
         <figure class="map-harmony">
            <img class="desk-img loadTemp" src="" :dta-src='this.$pathprod+"images/saint-ouen/desk-batiment-harmony.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/saint-ouen/desk-batiment-harmony-retina.jpg 2x"' alt="batiment-harmony">
            <img class="mob-img loadTemp_mobile" src="" :dtamob-src='this.$pathprod+"images/saint-ouen/mob-batiment-harmony.jpg"' alt="batiment-harmony">
         </figure>
      </section>

      <SaintOuen5/>
      <SaintOuen6/>
      <section id="envole-tertiaire" class="section-saint-ouen-7" :class="{'increment': increment}">
         <div class="envolTer__grid mb-30 show_animBloc" v-view="fadeAnimBloc2" :class="{'fade-up': show_animBloc2}">
            <div class="sct-left">
               <h2 class="title-h2">{{$t('titileTertiaire')}}</h2>
               <figure><img :src='this.$pathprod+"images/saint-ouen/saintOuen-Tertiaire.jpg"' :srcset='this.$pathprod+"images/saint-ouen/saintOuen-Tertiaire-retina.jpg 2x"' alt="img-envTertiaire"></figure>
            </div>

            <div class="sct-right displayBox" v-view="playIncreaseNumber1">
               <h2 class="title-h2 mob-title-h2">{{$t('dataKeyChiffreTertiaire')['0']}}</h2>
               <div class="key-number-item mb-5">
                  <p class="key-number"><span class="number-to-animate" data-number="1400000">
                        <number
                           ref="number1"
                           :from="0"
                           :to="1400000"
                           :format="theFormat"
                           animationPaused
                           :duration="2"
                           easing="Power1.easeOut"
                        />
                        </span> m<span class="m2">2</span></p>
                  <p class="key-desc">{{$t('dataKeyChiffreTertiaire')['1']}}</p>
               </div>
               <div class="key-number-item mb-5">
                  <p class="key-number"><span class="number-to-animate" data-number="95000">
                        <number
                           ref="number2"
                           :from="0"
                           :to="95000"
                           :format="theFormat"
                           animationPaused
                           :duration="3"
                           easing="Power1.easeOut"
                        /></span></p>
                  <p class="key-desc">{{$t('dataKeyChiffreTertiaire')['2']}}</p>
               </div>
               <div class="mb-30">
                  <h4 class="title-h4">{{$t('dataKeyChiffreTertiaire')['3']}}</h4>
                  <p>{{$t('dataKeyChiffreTertiaire')['4']}}</p>
               </div>
               <div id="logoPartenaire" class="logo-partenaire">
                  <a :href="image.ref" v-for="image in images" :key="image.id" rel="noopener" target="_blank">
                        <figure><img :src='image.src' :alt="image.alt"/></figure>
                  </a>
               </div>
            </div>
         </div>

         <div class="envolTer__grid envolTer__grid-sct-2 show_animBloc" v-view="fadeAnimBloc3" :class="{'fade-up': show_animBloc3}">
            <div class="sct-left">
               <div class="envlTer_title">
                  <h4 class="title-h4">{{$t('dataKeyChiffreTertiaire')['5']}}</h4>
                  <p><strong>{{$t('dataKeyChiffreTertiaire')['6']}}</strong> {{$t('dataKeyChiffreTertiaire')['7']}}</p>
               </div>
               <figure class="envlTer_img">
                  <img :src='this.$pathprod+"images/saint-ouen/saintOuen-footer.jpg"' alt="img-footer">
               </figure>
            </div>
            
            <div class="sct-rightCustom displayBox" v-view="playIncreaseNumber2">
               <div class="key-number-item mb-5">
                     <p class="key-number"><span class="number-to-animate">
                        <number
                           ref="number3"
                           :from="0"
                           :to="300000"
                           :format="theFormat"
                           animationPaused
                           :duration="3"
                           easing="Power1.easeOut"
                        />
                        </span> m<span class="m2">2</span></p>
                     <p class="key-desc">{{$t('dataKeyChiffreTertiaire')['8']}}</p>
               </div>
               <div class="key-number-item mb-5">
                     <p class="key-number"><span class="number-to-animate" data-number="67000">
                        <number
                           ref="number4"
                           :from="0"
                           :to="67000"
                           :format="theFormat"
                           animationPaused
                           :duration="3"
                           easing="Power1.easeOut"
                        />
                        </span> m<span class="m2">2</span></p>
                     <p class="key-desc">{{$t('dataKeyChiffreTertiaire')['9']}}</p>
               </div>
               <div class="key-number-item mb-5">
                     <p class="key-number"><span class="number-to-animate" data-number="5000">
                        <number
                           ref="number5"
                           :from="0"
                           :to="5000"
                           :format="theFormat"
                           animationPaused
                           :duration="3"
                           easing="Power1.easeOut"
                        />
                        </span></p>
                     <p class="key-desc">{{$t('dataKeyChiffreTertiaire')['10']}}</p>
               </div>
               <div class="key-number-item mb-5">
                     <p class="key-number"><span class="number-to-animate" data-number="12">
                        <number
                           ref="number6"
                           :from="0"
                           :to="12"
                           :format="theFormat"
                           animationPaused
                           :duration="3"
                           easing="Power1.easeOut"
                        />
                        </span> ha</p>
                     <p class="key-desc">{{$t('dataKeyChiffreTertiaire')['11']}}</p>
               </div>
               <div class="key-number-item mb-5">
                     <p class="key-number"><span class="number-to-animate" data-number="68000">
                        <number
                           ref="number7"
                           :from="0"
                           :to="68000"
                           :format="theFormat"
                           animationPaused
                           :duration="3"
                           easing="Power1.easeOut"
                        />
                        </span> m<span class="m2">2</span></p>
                     <p class="key-desc">{{$t('dataKeyChiffreTertiaire')['12']}}</p>
               </div>
            </div>
         </div>
      </section>

   </div>
</template>

<script>
   // Observer.vue
   export default {
      name: 'SaintOuen',
      props: {
         msg: String,
      },
      
      metaInfo() {
         return { 
            title: "Harmony - Saint-ouen énergie cinétique",
            meta: [
               { name: 'description', content:  'Suivre la ligne, HARMONY est accessible par la ligne 14, le RER C et en vélo. Ça bouge à Saint-Ouen, venez découvrir le quartier autour d’HARMONY.'},
               { property: 'og:title', content: "Harmony - Saint-ouen énergie cinétique"},
               { property: 'og:site_name', content: 'Harmony'},
               { property: 'og:type', content: 'website'},    
            ]
         }
      },

      data(){
         return{
            scroll: null,
            increment: false,
            showZoom: false,
            AnimtxtBlur: false,
            AnimLoc: false,
            showBrushK:false,
            show_animBloc: false,
            show_animBloc2: false,
            show_animBloc3: false,
            viewPortAnim: 0.2,
            images:[
               {id:1, src: this.$pathprod+"images/logo/logo-allianz.svg", ref: "https://www.allianz.com", alt:"logo-allianz"},
               {id:2, src: this.$pathprod+"images/logo/logo-alstom.svg", ref: "https://www.alstom.com", alt:"logo-alstom"},
               {id:3, src: this.$pathprod+"images/logo/logo-amazon.svg", ref: "https://www.amazon.com", alt:"logo-amazon"},
               {id:4, src: this.$pathprod+"images/logo/logo-bic.svg", ref: "https://www.bic.com", alt:"logo-bic"},
               {id:5, src: this.$pathprod+"images/logo/logo-samsung.svg", ref: "https://www.samsung.com", alt:"logo-samsung"},
               {id:6, src: this.$pathprod+"images/logo/logo-ocp.svg", ref: "https://www.ocp.com", alt:"logo-ocp"},
               {id:7, src: this.$pathprod+"images/logo/logo-monoprix.svg", ref: "https://www.monoprix.com", alt:"logo-monoprix"},
               {id:8, src: this.$pathprod+"images/logo/logo-oreal.svg", ref: "https://www.oreal.com", alt:"logo-oreal"}
            ],
         }
      },

      created () {
         window.addEventListener('scroll', this.handleScroll);
      },

      destroyed () {
         window.removeEventListener('scroll', this.handleScroll);
      },

      components: {
         VideoSaintOuen: () => import('@/components/video/VideoSaintOuen.vue'),
         SaintOuen5: () => import('@/components/Page-saintOuen/SaintOuen5.vue'),
         SaintOuen6: () => import('@/components/Page-saintOuen/SaintOuen6.vue'),
      },

      methods: {
         scrollToNext(e){
            e.preventDefault();
            var container = this.$el.querySelector(".saintOuen-2");
            container.scrollTop = container.scrollHeight;
            container.scrollIntoView({behavior: 'smooth'});
         },

         /*Fonction beginAnimationZoom*/
         beginAnimationZoom(){
            this.showZoom = true;
            setTimeout(() => {
               var showCircle = document.querySelector(".loc-bloc-circle")
               showCircle.style.opacity = "1"
               setTimeout(() => {
                    this.AnimLoc = true;
               }, 600);
            }, 500);
         },

         /*Fonction executé pour le scroll*/
         handleScroll() {
            const el = document.querySelector('.wallpaper-zoom-inactive');
            if(this.increment == false){
               if(window.scrollY >=  el.offsetTop - 200){
                  this.increment = true;
                  setTimeout(() => {
                       this.beginAnimationZoom();
                  }, 500);
               }
            }
         },

         playIncreaseNumber1(e) {
            if(e.type == "enter"){
               this.$refs.number1.play()
               this.$refs.number2.play()
            }
         },
         playIncreaseNumber2(e) {
            if(e.type == "enter"){
               this.$refs.number3.play()
               this.$refs.number4.play()
               this.$refs.number5.play()
               this.$refs.number6.play()
               this.$refs.number7.play()
            }
         },
         theFormat(number){
            return this.numberWithSpaces(number.toFixed(0));
         },
         numberWithSpaces(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
         },
         fadeAnimBloc(e){
            if(e.type == "progress" && e.percentInView >= this.viewPortAnim){
               this.show_animBloc = true
            }
         },
         fadeAnimBloc2(e){
            if(e.type == "progress" && e.percentInView >= this.viewPortAnim){
               this.show_animBloc2 = true
            }
         },
         fadeAnimBloc3(e){
            if(e.type == "progress" && e.percentInView >= this.viewPortAnim){
               this.show_animBloc3 = true
            }
         }
      },

      mounted(){
         //Play entete video if component is ready
         setTimeout(() => {
            var video = document.querySelector("#saintOuen-1 .bg-hero")
            setTimeout(() => {
               this.AnimtxtBlur = true;
            }, 3000);
            if(video){
               video.play()  
               
               var inter = setInterval(() =>{
                  if(video.paused && video.ended == false ){
                     video.play()
                  }
               }, 500);
               
               video.addEventListener("ended", function(){
                  clearInterval(inter)
               })
            }
         }, 1500);

         //Load lazy img
         setTimeout(() => {
            var screen_width = window.matchMedia("(max-width: 991px)")
            loadImg(screen_width)
         }, 1500);

          //Charger les images mobiles ou desktop
         function loadImg(device){
            if(device.matches){
               /*Diffère tous les images mobile*/
               let loadImg = document.querySelectorAll('.loadTemp_mobile');
               for(let i = 0; i < loadImg.length; i++){
                  loadImg[i].setAttribute('src',loadImg[i].getAttribute('dtamob-src'));
               }

            }else{
               /*Diffère tous les images desktop*/
               setTimeout(function(){
                  let loadImg = document.querySelectorAll('.loadTemp');
                  for(let i = 0; i < loadImg.length; i++){
                     loadImg[i].setAttribute('src',loadImg[i].getAttribute('dta-src'));
                     loadImg[i].setAttribute('srcset',loadImg[i].getAttribute('dta-srcset'));
                  }
               }.bind(this), 1000);
            }
         }
      }
   };

</script>